import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { useEffect, useState, useRef } from 'react'
import { PRIVACY_POLICY_DOCUMENT } from '../constants'
import Alert from 'react-bootstrap/Alert'
import { StarFill } from 'react-bootstrap-icons'
import YardButton from '../yardButton'

function parseAddressComponents(components) {
  let parsedAddress = {
    line1: '',
    city: '',
    state: '',
    postal_code: '',
    country: ''
  }

  components.forEach(component => {
    const types = component.types;
    const value = component.long_name;

    if (types.includes('street_number')) {
      parsedAddress.line1 = value + ' ' + parsedAddress.line1;
    } else if (types.includes('route')) {
      parsedAddress.line1 += value;
    } else if (types.includes('locality')) {
      parsedAddress.city = value;
    } else if (types.includes('administrative_area_level_1')) {
      parsedAddress.state = value;
    } else if (types.includes('postal_code')) {
      parsedAddress.postal_code = value;
    } else if (types.includes('country')) {
      parsedAddress.country = value;
    }
  })

  return parsedAddress;
}

export default function ContactInfo(props) {
  const autocompleteRef = useRef(null);
  const [googlePlace, setGooglePlace] = useState(null);
  const [referrer, setReferrer] = useState(props.data.referrer || 'door-hanger');

  useEffect(() => {
    if (!autocompleteRef.current){
      return;
    }

    if (!window.google || !window.google.maps.places) {
      return;
    }

    const autocomplete = new window.google.maps.places.Autocomplete(autocompleteRef.current)

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace()
      setGooglePlace(place)
    });
  }, []);

  function onSubmit(e) {
    e.preventDefault()
    const formData = new FormData(e.target)
    const data = Object.fromEntries(formData)

    data.referrer = referrer

    if (googlePlace) {
      data.googlePlace = googlePlace
      data.addressParsed = parseAddressComponents(googlePlace.address_components || [])
    }
    
    props.onSubmit(data)
  }

  const fiveStars = <span className='text-nowrap align-middle pe-1'>
    <StarFill className='align-baseline' fill='#fdd663'/>
    <StarFill className='align-baseline' fill='#fdd663'/>
    <StarFill className='align-baseline' fill='#fdd663'/>
    <StarFill className='align-baseline' fill='#fdd663'/>
    <StarFill className='align-baseline' fill='#fdd663'/>
  </span>

  const referrerOptions = [{
    label: 'Door Hanger',
    value: 'door-hanger'
  }, {
    label: 'Google',
    value: 'google'
  }, {
    label: 'Facebook',
    value: 'facebook'
  }, {
    label: 'Other',
    value: 'other'
  }]

  return <div className='d-flex flex-column'>
    <Alert variant='light'>
      <div>{fiveStars}<span>Yard is trusted by hundreds of customers in Huntsville, Madison, and surrounding areas. <a href='https://goo.gl/maps/JHpKXwNMGiv2xjcM6' target='_blank' rel='noreferrer'>Read our Google Reviews here.</a></span></div>
    </Alert>
    {(props.data.promotionalMows > 0 || props.data.deals.length > 0) && <Alert variant='light'>
      {props.data.promotionalMows > 0 &&
      <div>Please complete the sign-up process to secure your {props.data.promotionalMows} free mow{props.data.promotionalMows > 1 ? 's': ''}.</div>}
      {props.data.deals.length > 0 && props.data.deals.map(deal => {
        if (deal === 'early-bird') {
          return <div>Please complete the sign-up process to secure $20 off your first service.</div>
        }

        return null
      })}
    </Alert>}
    <Form className='d-flex flex-column' onSubmit={onSubmit}>
      <Form.Group className='mb-2'>
        <Form.Label htmlFor='address' hidden={true}>Address of the Service</Form.Label>
        <Form.Control defaultValue={props.data.address} required size='lg' name='address' type='text' placeholder='Address of the Service' ref={autocompleteRef} autoComplete='street-address' />
      </Form.Group>
      <Form.Group className='mb-2'>
        <Form.Label htmlFor='name' hidden={true}>Name</Form.Label>
        <Form.Control defaultValue={props.data.name} required size='lg' name='name' type='text' placeholder='Full Name' autoComplete='name' />
      </Form.Group>
      <Form.Group className='mb-2'>
        <Form.Label htmlFor='email' hidden={true}>Email address</Form.Label>
        <Form.Control defaultValue={props.data.email} required size='lg' name='email' type='email' placeholder='Email Address' autoComplete='email' />
      </Form.Group>
      <Form.Group className='mb-2'>
        <Form.Label htmlFor='phone' hidden={true}>Phone Number</Form.Label>
        <Form.Control defaultValue={props.data.phone} required size='lg' name='phone' type='text' placeholder='Phone Number' autoComplete='tel' />
      </Form.Group>
      <Form.Group className='mb-4 mt-2'>
        <Form.Label className='text-muted' htmlFor='referrer' hidden={false}>How did you hear about us?</Form.Label>
        <div>
          {referrerOptions.map(option => {
            return <Button
                style={{minWidth: 120}}
                className='ms-0 mt-0 me-2 mb-2'
                variant={'outline-primary' + (referrer === option.value ? ' active' : '')}
                onClick={e => setReferrer(option.value)}
                key={option.value}>
              {option.label}
            </Button>
          })}
        </div>
      </Form.Group>

      <YardButton className='mt-2 mb-3' variant='primary' type='submit'>Next</YardButton>
      
      <div className='text-muted'><small style={{fontSize: '0.8em'}}>By proceeding, you agree to our <a target='_blank' rel='noreferrer' href={PRIVACY_POLICY_DOCUMENT}>Privacy Policy</a> and to get texts about your lawn service. Text rates apply. Text STOP to unsubscribe, HELP for help.</small></div>
    </Form>
  </div>
}