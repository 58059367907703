import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import Button from 'react-bootstrap/Button'
import ContactInfo from './quote/contact'
import SizeSelection from './quote/sizeSelection'
import Price from './quote/price'
import Confirm from './quote/confirm'
import { DepositStep } from './quote/deposit'
import Api from './api'
import { useNavigate, useLocation } from 'react-router-dom'
import { generateId } from './utils'
import { DEPOSIT_AMOUNT } from './constants'

export const DEPOSIT_HEADER = `Reserve your service with a $${DEPOSIT_AMOUNT / 100} deposit`

function updateClarity(data, stage) {
  if (!window.clarity) {
    return
  }

  if (!data.email || data.name || data.id) {
    return
  }

  window.clarity('identify', data.email, data.id, stage, data.name)
}

export function Quote() {
  const navigate = useNavigate()
  const location = useLocation()

  console.log('QUOTE RENDER')

  const stages = [{
    header: 'How do we reach you?',
    name: 'contact',
    element: ContactInfo
  }, {
    header: 'How big is your place?',
    name: 'size',
    element: SizeSelection
  }, {
    header: 'Mowing cadence',
    name: 'cadence',
    element: Price
  }, {
    header: 'Confirming your details',
    name: 'confirm',
    element: Confirm
  }, {
    header: DEPOSIT_HEADER,
    name: 'reserve',
    element: DepositStep
  }]
  
  const prices = [
    {name: 'Small', weekly: 54, biweekly: 74, minSize: 0, maxSize: 10000, maxSizeAcres: '1/4'},
    {name: 'Medium', weekly: 59, biweekly: 89, minSize: 10000, maxSize: 15000, minSizeAcres: '1/4', maxSizeAcres: '1/3'},
    {name: 'Large', weekly: 69, biweekly: 109, minSize: 15000, maxSize: 20000, minSizeAcres: '1/3', maxSizeAcres: '1/2'},
    {name: 'Extra Large', weekly: 0, biweekly: 0, minSize: 20000, maxSize: 0, minSizeAcres: '1/2'},
    {name: 'Not sure', weekly: 0, biweekly: 0, minSize: 20000, maxSize: 0}]

  const queryParams = new URLSearchParams(location.search)

  const promotionalMows = parseInt(new URLSearchParams(location.search).get('m', 0))
  const twentyOffDeal = new URLSearchParams(location.search).get('d') === 'early-bird'

  let existingId = queryParams.get('id')
  let existingData = null
  if (localStorage.getItem('quote:' + existingId)) {
    existingData = JSON.parse(localStorage.getItem('quote:' + existingId))
    const timeout = 10 * 60 * 1000 // 10 minutes
    if (!existingData.updatedAt || new Date(existingData.updatedAt) < new Date(new Date().getTime() - timeout)) {
      console.log('too old')
      existingId = null
      existingData = null
    }
  }

  const [data, setData] = useState(existingData ? existingData : {
    id: existingId || generateId(),
    stage: 0,
    referrer: undefined,
    stageName: stages[0].name,
    promotionalMows,
    deals: twentyOffDeal ? ['early-bird'] : []
  })

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const id = queryParams.get('id')

    if (!id || id !== data.id) {
      queryParams.set('id', data.id);
      navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
    }
  }, [location, navigate, data.id])

  function updateData(newData) {
    newData = _.extend(newData, {
      updatedAt: new Date().toISOString()
    })

    uploadLocalStorage(_.extend(data, newData))
    setData(prevData => ({...prevData, ...newData}))
  }

  function onSubmit(formData) {
    const newStage = data.stage + 1
    const newData = _.extend(formData, {
      stage: newStage,
      stageName: stages[newStage].name,
      updatedAt: new Date().toISOString()
    })

    const combined = _.extend(data, newData)
    Api.updateQuote(combined)
    updateClarity(combined, stages[newStage].name)
    uploadLocalStorage(combined)
    setData(prevData => ({...prevData, ...newData}))
  }

  function uploadLocalStorage(data) {
    localStorage.setItem('quote:' + data.id, JSON.stringify(data))
  }

  const stageCount = stages.length

  let header = 'Welcome!'
  let preHeader = `Let's get you a quote. Step ${data.stage + 1} of ${stageCount}.`
  if (data.stage >= 3) {
    preHeader = `Almost done. Step ${data.stage + 1} of ${stageCount}.`
  }
  
  if (data.stage === stageCount - 1) {
    preHeader = `Final step. Step ${data.stage + 1} of ${stageCount}.`
  }

  let showBack = data.stage > 0
  header = stages[data.stage].header
  
  const form = React.createElement(stages[data.stage].element, { 
    prices,
    data,
    amount: DEPOSIT_AMOUNT,
    onSubmit: formData => onSubmit(formData),
    updateData
  });

  if (data.stage === 0) {
    Api.updateQuote(data)
  } else if (data.stage === 1) {
    preHeader = `Nice to meet you ${data.name.split(' ')[0]}.`
  } else if (data.stage === 2) {
  } else if (data.stage === 3) {
  } else if (data.stage === 4) {
  } else if (data.stage === 5) {
  }

  return <div className='p-4' style={{maxWidth: '600px'}}>
    <div className='text-muted'>{preHeader}</div>
    <div className='display-4 mb-2'>{header}</div>
    <div className='mb-0'>
      {form}
    </div>
    {showBack && <Button className='ps-0' variant='link' size='lg' onClick={ev => {
      ev.preventDefault()
      updateData({stage: data.stage - 1, stageName: stages[data.stage - 1].name})
    }}>Back</Button>}
  </div>
}