import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Quote } from './quote';
import ThankYou from './quote/thankYou'
import Page from './page'
import DepositStandalone from './depositStandalone'
import { TwentyOff } from './earlyBird/banner'
import Api from './api'

function wrap(component) {
  return <Page>{component}</Page>
}

const router = createBrowserRouter([{
    element: wrap(<App />),
    path: '/',
  }, {
    element: wrap(<Quote />),
    path: '/quote',
  }, {
    element: wrap(<ThankYou />),
    path: '/complete'
  }, {
    element: wrap(<DepositStandalone />),
    path: '/deposit'
  }, {
    element: wrap(<TwentyOff />),
    path: '/early-bird'
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

window.onerror = function myErrorHandler(errorMsg, url, lineNumber) {
  Api.logError({
    message: errorMsg,
    url,
    lineNumber,
    source: 'web'
  })
}
