import Form from 'react-bootstrap/Form'
import classnames from 'classnames'
import { useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import YardButton from '../yardButton'

function getRangeText(price) {
  let rangeText = `${price.minSize.toLocaleString()} to ${price.maxSize.toLocaleString()} sq ft (${price.minSizeAcres} to ${price.maxSizeAcres} acre) of grass.`
  if (price.minSize === 0 && price.maxSize !== 0) {
    rangeText = `Less than ${price.maxSize.toLocaleString()} sq ft (${price.maxSizeAcres} acre) of grass.`
  }

  if (price.minSize !== 0 && price.maxSize === 0) {
    rangeText = `More than ${price.minSize.toLocaleString()} sq ft (${price.minSizeAcres} acre) of grass.`
  }

  if (price.minSize === 0 && price.maxSize === 0) {
    rangeText = 'Any size.'
  }

  if (price.name === 'Not sure') {
    rangeText = 'We can help you measure your lawn size.'
  }

  return rangeText
}

export function PropertySize({entry}) {
  return <div>
    <div className='display-6'>{entry.name}</div>
    <div className='text-muted'>{getRangeText(entry)}</div>
  </div>
}

export default function SizeSelection(props) {
  const [chosen, setChosen] = useState(props.data.priceName || 'Medium')

  function onSubmit(e) {
    e.preventDefault()
    props.onSubmit({
      priceName: chosen,
      noQuote: chosen === 'Not sure' || chosen === 'Extra Large',
      weeklyPricePerService: props.prices.find(p => p.name === chosen).weekly,
      biweeklyPricePerService: props.prices.find(p => p.name === chosen).biweekly
    })
  }

  const googleMapsBreakout = <span>{props.data.googlePlace && <span>
    To <a href={props.data.googlePlace.url} target='_blank' rel='noreferrer'>open your property on Google Maps, click here.</a>
  </span>}</span>

  return <Form className='d-flex flex-column' onSubmit={onSubmit}>
    {props.prices.map(price => {
      const classes = classnames({
        'p-3 mb-3 border rounded': true,
        'bg-primary text-white': chosen === price.name
      })

      return <div key={price.name} className={classes} role='button' onClick={ev => {
        ev.preventDefault()
        setChosen(price.name)
      }}>
        <PropertySize entry={price} />
      </div>
    })}

    {chosen === 'Not sure' && false && googleMapsBreakout &&
      <Alert variant='light'>
        {googleMapsBreakout} Please feel free to continue and we will help you measure your lawn size.
      </Alert>}

    <YardButton className='mt-2 mb-3' variant='primary' type='submit'>Next</YardButton>
  </Form>
}