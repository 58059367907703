import { useEffect, useState } from 'react'
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import Api from '../api'
import Spinner from 'react-bootstrap/Spinner'
import {STRIPE_PUBLIC_KEY} from '../constants'
import DepositDetails from './depositDetails';
import Alert from 'react-bootstrap/Alert'

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY)

export function DepositStep({data, amount}) {
  const [clientSecret, setClientSecret] = useState(data.clientSecret ? data.clientSecret : null)
  
  useEffect(() => {
    if (clientSecret) {
      return
    }

    Api.createDepositPaymentIntent({
      quoteId: data.id,
      amount,
      name: data.name,
      email: data.email,
      phone: data.phone,
      address: data.addressParsed
    }).then(clientSecret => {
      setClientSecret(clientSecret)
    })
  }, [data.id, amount, data.name, data.email, data.phone, data.addressParsed, clientSecret])

  if (!clientSecret){
    return <div>
      <Spinner />
      <div>Loading...</div>
    </div>
  }

  return <div>
    <Alert variant='dark'>
      {!data.noQuote && <div>Your deposit ensures that we add you to our schedule, and it will be deducted from your first payment.</div>}
      {data.noQuote && <div>If you choose to reject the final quote, or if we are unable to provide a quote for your property, the deposit will be refunded.</div>}
    </Alert>
    <Elements stripe={stripePromise} options={{clientSecret, appearance: {theme: 'night'}}}>
      <DepositDetails quoteId={data.id} amount={amount} />
    </Elements>
  </div>
}