import Form from 'react-bootstrap/Form'
import classnames from 'classnames'
import { useState } from 'react'
import { Badge } from 'react-bootstrap'
import YardButton from '../yardButton'

export const WEEKLY_NAME = 'Weekly'
export const BIWEEKLY_NAME = 'Bi-weekly'

export default function Price({prices, data, onSubmit}) {
  const chosenPrice = prices.find(price => price.name === data.priceName)
  const [chosenFrequency, setChosenFrequency] = useState(data.frequency || WEEKLY_NAME)

  function onSubmitLocal(e) {
    e.preventDefault()
    onSubmit({frequency: chosenFrequency, isWeekly: chosenFrequency === WEEKLY_NAME})
  }
  
  return <div>
    <Form className='d-flex flex-column' onSubmit={onSubmitLocal}>
    {[{
      name: WEEKLY_NAME,
      price: chosenPrice.weekly === 0 ? 'TBD' : chosenPrice.weekly,
      mowsPerMonth: 4,
      monthlyPrice: chosenPrice.weekly * 4,
      tagLine: 'Best quality',
      badgeBg: 'success'
    }, {
      name: BIWEEKLY_NAME,
      price: chosenPrice.biweekly === 0 ? 'TBD' : chosenPrice.biweekly,
      mowsPerMonth: 2,
      monthlyPrice: chosenPrice.biweekly * 2,
      tagLine: 'Best value',
      badgeBg: 'info'
    }].map(frequency => {
      const classes = classnames({
        'p-3 mb-3 border rounded': true,
        'bg-primary text-white': chosenFrequency === frequency.name
      })

      return <div key={frequency.name} className={classes} role='button' onClick={ev => {
        ev.preventDefault()
        setChosenFrequency(frequency.name)
      }}>
        <div className='d-flex align-items-center'>
          <div className='display-6 fw-bold d-inline-block'>{frequency.name} service</div>
          <Badge pill style={{minWidth: '100px'}} className='p-2 ms-2' bg={frequency.badgeBg}>{frequency.tagLine}</Badge>
        </div>
        {!data.noQuote && <div className='mb-2 d-flex align-items-center'><span className='display-6 fw-lighter'>${frequency.price} per mow</span></div>}
        <div>Billed every {frequency.mowsPerMonth} mows in advance.</div>
      </div>
    })}

    <div className='mb-3'>
      <div className='mb-2'>
        <Form.Check disabled={true} checked={true} className='form-control-lg mb-0 pb-0' name='switch' label='Change your mind at any time' />
        <div className='text-muted'>You can switch between weekly and bi-weekly services at any time.</div>
      </div>
    </div>

    <YardButton className='mt-2 mb-3' variant='primary' type='submit'>Next</YardButton>
  </Form>
  </div>
}